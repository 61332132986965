<template>
  <div class="singpage-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-49.mp3"),
      },
      timeStampList: [5.2, 10.6, 21.5, 24, 26, 29, 33],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Xuéxiào, shāngdiàn, chāoshì,",
            chineseWords: "学校、商店、超市；",
          },
          {
            id: 2,
            spelling: "Xuéxiào, shāngdiàn, chāoshì,",
            chineseWords: "学校、商店、超市；",
          },
          {
            id: 3,
            spelling: "Māma qù shāngdiàn.",
            chineseWords: "妈妈去商店。",
          },
          {
            id: 4,
            spelling: "Bàba qù chāoshì.",
            chineseWords: "爸爸去超市。",
          },
          {
            id: 5,
            spelling: "Nǐ qù nǎr?",
            chineseWords: "你去哪儿？",
          },
          {
            id: 6,
            spelling: "Wǒ qù xuéxiào.",
            chineseWords: "我去学校。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
