<template>
  <div class="game-container">
    <MatchTheCardsGame
      :imgList="imgList"
      @changeCardBG="changeCardBG"
      :lessonNum="45"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import MatchTheCardsGame from "@/components/Course/GamePage/MatchTheCardsGame";
export default {
  data() {
    return {
      titleInfo: {
        pinyin: "…qù nǎr？",
        hanzi: "……去哪儿？",
      },
      imgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49-2/boy.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49-2/elderly.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49-2/girl.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49-2/woman.svg"),
          type: 0,
          cardActive: false,
        },

        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49-2/girl-toilet.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49-2/elderly-toilet.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49-2/boy-toilet.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49-2/woman-toilet.svg"),
          type: 0,
          cardActive: false,
        },
      ],
    };
  },
  components: {
    MatchTheCardsGame,
  },
  methods: {
    changeCardBG(index, firstIndex, type) {
      this.imgList[index].type = type;
      this.imgList[index].cardActive = false;
      this.imgList[firstIndex].type = type;
      this.imgList[firstIndex].cardActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
