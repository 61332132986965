<template>
  <div class="game-container">
    <MatchTheCardsGame
      :imgList="imgList"
      @changeCardBG="changeCardBG"
      :lessonNum="45"
      :titleInfo="titleInfo"
    />
  </div>
</template>

<script>
import MatchTheCardsGame from "@/components/Course/GamePage/MatchTheCardsGame";
export default {
  data() {
    return {
      titleInfo:{
          pinyin:"Shéi zài zhèr?",
          hanzi:"谁在这儿？"
      },
      imgList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49/boy-1.svg"),
          type: 0,
          cardActive: false,
        },
       
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49/girl-1.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49/girl-2.svg"),
          type: 0,
          cardActive: false,
        },
 {
          id: 2,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49/boy-2.svg"),
          type: 0,
          cardActive: false,
        },
        
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49/girl-1-place.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49/boy-2-place.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49/boy-1-place.svg"),
          type: 0,
          cardActive: false,
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G29-match-cards/lesson-49/girl-2-place.svg"),
          type: 0,
          cardActive: false,
        },
      ],
    };
  },
  components: {
    MatchTheCardsGame,
  },
  methods: {
    changeCardBG(index, firstIndex, type) {
      this.imgList[index].type = type;
      this.imgList[index].cardActive = false;
      this.imgList[firstIndex].type = type;
      this.imgList[firstIndex].cardActive = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











