<template>
  <div class="game-container">
    <MirrorGame :mirrorImgList="mirrorImgList" :optionList="optionList" :bgImg="bgImg" :titleInfo="titleInfo"/>
  </div>
</template>

<script>
import MirrorGame from "@/components/Course/GamePage/MirrorGame";
export default {
  data() {
    return {
      bgImg:require("@/assets/img/16-GAMES/G03-mirror/lesson-49/background.svg"),
      optionList: [
        {
          pinyin: "shì",
          hanzi: "是",
          id: 0,
        },
        {
          pinyin: "bù shì",
          hanzi: "不是",
          id: 1,
        },
      ],
      mirrorImgList: [
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-49/image-1-shangdian.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-49/image-2.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-49/image-3.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-49/image-4.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-49/image-5-shangdian.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-49/image-6.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-49/image-7.svg"),
          id: 1,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-49/image-8-shangdian.svg"),
          id: 0,
        },
        {
          imgSrc: require("@/assets/img/16-GAMES/G03-mirror/lesson-49/image-9.svg"),
          id: 1,
        },
      ],
      titleInfo:{
        pinyin:"Zhèr shì shāngdiàn ma?",
        hanzi:"这儿是商店吗？",
      }
    };
  },
  components: {
    MirrorGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;

  ::v-deep .mirror-img {
    width: 30% !important;
  }
}
</style>











