<template>
  <div class="wordscard-container layout-border">
    <WordCards :wordCardList="wordCardList" @changeDisplay="handleChangeDisplay" 
    :lessonNum="11"/>
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: 'redColor',
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "qù",
          hanzi: "去",
          english: "to go",
          image: require(`@/assets/img/12-Vocabulary/lesson-49-qu-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "xuéxiào",
          hanzi: "学校",
          english: "school",
          image: require(`@/assets/img/12-Vocabulary/lesson-49-xuexiao-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "shāngdiàn",
          hanzi: "商店",
          english: "store",
          image: require(`@/assets/img/12-Vocabulary/lesson-49-shangdian-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "chāoshì",
          hanzi: "超市",
          english: "supermarket",
          image: require(`@/assets/img/12-Vocabulary/lesson-49-chaoshi-bubble.svg`),
        },
        {
          bgColor: 'redColor',
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "... qù nǎr ?",
          hanzi: "……去哪儿？",
          english: "Where are/is ... going?",
          image: require(`@/assets/img/12-Vocabulary/lesson-49-qu-nar.svg`),
          blue: true,
          longtext:true
        },
      ],
    };
  },
  methods:{
    handleChangeDisplay(index,isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg
    }
  }
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
