<template>
  <div class="game-container">
    <MagnifierGame
      :optionList="optionList"
      :bgImg="bgImg"
      :lessonNo="49"
      :title="title"
    />
  </div>
</template>

<script>
import MagnifierGame from "@/components/Course/GamePage/MagnifierGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-49/book-background.svg"),
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-49/biscuits.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-49/cart.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-49/cashier.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-49/chips.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/lesson-49/milk.svg"),
          // number: 10,
          // showObj: true,
        },
      ],
      title: {
        pinyin: "Chāoshì li yǒu shénme?",
        hanzi: "超市里有什么?",
      },
    };
  },
  components: {
    MagnifierGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>
