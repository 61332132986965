var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rank-map-page-one" },
    [
      _c("RankMapPage", {
        attrs: {
          lesson: _vm.lessonNum,
          position: _vm.positionNum,
          chapterNum: _vm.chapter,
          chapterString: "7",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }